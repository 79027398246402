import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './assets/logo-final.png';
import citiesData from './assets/cities.json';
import { Box, Container, Typography, Select, MenuItem, Table, TableBody, TableCell, 
  TableContainer, TableRow, Link, Divider, Grid, Button } from '@mui/material';


const SearchEngine = () => {
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('not found');
  const [address, setAddress] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [openingHours, setOpeningHours] = useState('');
  const [towingFee, setTowingFee] = useState('');
  const [storageFee, setStorageFee] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [apiSearch, setApiSearch] = useState('');

/**
 * Renders a Google Maps clickable link for a given address tag.
 * @param {string} text - The input text containing {address} tags.
 * @returns {string} - Processed text with Google Maps clickable links.
 */
const renderGoogleMapLinks = (text) => {
  const addressTagRegex = /\{address\}(.*?)\{address\}/g;

  return text.replace(addressTagRegex, (match, address) => {
    // Remove <strong> tags if present
    const cleanedAddress = address.replace(/<strong>|<\/strong>/g, '').trim();
    
    const googleMapsLink = `https://www.google.com/maps/search/${encodeURIComponent(cleanedAddress)}`;
    return `<a href="${googleMapsLink}" target="_blank" rel="noopener noreferrer" style="color: #38ADEF; text-decoration: none;">
              ${cleanedAddress}
            </a>`;
  });
};

/**
 * Renders clickable phone links for phone tags in the text.
 * @param {string} text - The input text containing {phone} tags.
 * @returns {string} - Processed text with clickable phone links.
 */
const renderPhoneLinks = (text) => {
  const phoneTagRegex = /\{phone\}(.*?)\{phone\}/g;

  return text.replace(phoneTagRegex, (match, phone) => {
    const sanitizedPhone = phone.replace(/[-.\s]/g, ''); // Clean the phone number
    return `<a href="tel:${sanitizedPhone}" style="color: #38ADEF; text-decoration: none;">
              ${phone.trim()}
            </a>`;
  });
};

/**
 * Processes content to render clickable Google Maps links and phone numbers.
 * @param {string} text - The input text containing {address} and {phone} tags.
 * @returns {string} - Fully processed text with clickable links.
 */
const processContent = (text) => {
  if (!text) return ''; // Guard clause for undefined text

  let processedText = renderGoogleMapLinks(text); // Process addresses first
  processedText = renderPhoneLinks(processedText); // Process phone numbers
  return processedText;
};

  const updateCityDetails = (city) => {
    const cityDetails = citiesData.find((cityDetails) => cityDetails.City === city);

    if (cityDetails) {
      setAddress(cityDetails["Addresses"]);
      setContactPhone(cityDetails["Contact Phone"]);
      setOpeningHours(cityDetails["Opening Hours"]);
      setTowingFee(cityDetails["Towing Fee (regular size)"]);
      setStorageFee(cityDetails["Storage Fee (regular size)"]);
      setAdditionalInformation(cityDetails["more info"]);
      setApiSearch(cityDetails["API search"]);
    } else {
      console.error('City details not found.');
    }
  };

  useEffect(() => {
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords;
          
          // Use Nominatim API for reverse geocoding
          axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`)
            .then(response => {
              console.log(response.data);

              const address = response.data.address;
              // const countryShort = address.country_code.toUpperCase();
              let cityName = address.city || address.town || address.village;
              setSelectedCity(cityName.replace("-"," "));

              const countryName = address.country
              setSelectedCountry(countryName)

              const cityDetails = citiesData.find(city => city.City === cityName); 
              if (cityDetails) {
                setSelectedCountry(cityDetails.Country);
                updateCityDetails(cityName);
              } else {
                console.error('City not found for the given location.');
              }
            })
            .catch(error => {
              console.error("Error reverse geocoding location:", error);
            });
        }, error => {
          console.error("Error getting geolocation:", error);
        });
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    if (!selectedCountry || selectedCity === 'not found') {
      return;
    }

    const cityDetails = citiesData.find(city => city.City === selectedCity && city.Country === selectedCountry);

    if (cityDetails) {
      updateCityDetails(selectedCity);
    } else {
      console.log('Selected city does not match the selected country.');
    }    

  }, [selectedCountry, selectedCity]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleLocationLinkClick = () => {
    setShowLocationSelector(true);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100%', minHeight: '100vh', overflow: 'auto' }}>
      <Box sx={{ backgroundColor: '#38ADEF', padding: '20px', width: '100%', textAlign: 'center' }}>
        <img src={logo} alt="Safe City Parking" width="300" />
      </Box>

      <Container sx={{ width: '100%', margin: '0px', padding: '0px' }}>
        <Box sx={{ padding: '10px' }}>
          <section>
            {showLocationSelector && (
              <Box>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                  {/* Country Dropdown */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2" // Smaller text
                      sx={{
                        fontWeight: '500',
                        color: '#555',
                        marginBottom: '4px', // Closer to dropdown
                        textAlign: 'left',
                      }}
                    >
                      Select a Country
                    </Typography>
                    <Select
                      id="Countries"
                      name="Countries"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      fullWidth
                      displayEmpty
                      variant="outlined"
                      sx={{
                        height: '36px', // Reduced height
                        minHeight: '36px',
                        backgroundColor: '#fff',
                        borderRadius: '6px',
                        fontSize: '0.875rem', // Smaller text inside dropdown
                        textAlign: 'left', // Align text inside dropdown to the left
                        '& .MuiSelect-select': {
                          textAlign: 'left', // Align selected text to the left
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#ddd',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#aaa',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#38ADEF',
                          borderWidth: '1px',
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Select a country</em>
                      </MenuItem>
                      {[...new Set(citiesData.map((city) => city.Country))]
                        .sort((a, b) => a.localeCompare(b))
                        .map((country, index) => (
                          <MenuItem key={index} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>

                  {/* City Dropdown */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: '500',
                        color: '#555',
                        marginBottom: '4px',
                        textAlign: 'left',
                      }}
                    >
                      Select a City
                    </Typography>
                    <Select
                      id="Cities"
                      name="Cities"
                      value={selectedCity}
                      onChange={handleCityChange}
                      fullWidth
                      displayEmpty
                      variant="outlined"
                      renderValue={(selected) =>
                        selected === "not found" ? <em>Select a city</em> : selected
                      }
                      sx={{
                        height: '36px',
                        minHeight: '36px',
                        backgroundColor: '#fff',
                        borderRadius: '6px',
                        fontSize: '0.875rem',
                        textAlign: 'left', // Align text inside dropdown to the left
                        '& .MuiSelect-select': {
                          textAlign: 'left', // Align selected text to the left
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#ddd',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#aaa',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#38ADEF',
                          borderWidth: '1px',
                        },
                      }}
                    >
                      <MenuItem value="not found" disabled>
                        <em>Select a city</em>
                      </MenuItem>
                      {selectedCountry ? (
                        citiesData
                          .filter((city) => city.Country === selectedCountry)
                          .sort((a, b) => a.City.localeCompare(b.City))
                          .map((city, index) => (
                            <MenuItem key={index} value={city.City}>
                              {city.City}
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem disabled>
                          <em>Please select a country first</em>
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                </Grid>
              </Box>
              </Box>
            )}

            {!showLocationSelector && (
            <Box id="serviceFound" sx={{ mt: 2 }}>
              <Typography><span style={{ fontWeight: 700}}>Your location: </span><span>{selectedCity}</span>{selectedCountry && <span>, </span>}<span>{selectedCountry}</span></Typography>
              <Link href="#" sx={{  display: 'block' }} onClick={handleLocationLinkClick}>Not your location?</Link>
            </Box>
            )}

            <Box id="searchResults" sx={{ display: 'none', mt: 2 }}>
              <Box id="found" sx={{ display: 'none' }}>
                <Typography id="searchResultText"></Typography>
              </Box>
              <Box id="notfound" sx={{ display: 'none' }}>
                <Typography id="searchResultText1"></Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 2, borderColor: 'primary.main', borderWidth: '1px' }} />

            <Box id="serviceDetails" sx={{ mt: 2 }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Address:</TableCell>
                      <TableCell id="serviceAddress">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: processContent(address),
                        }}
                      />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Contact phone:</TableCell>
                      <TableCell id="servicePhone">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: processContent(contactPhone),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Opening hours:</TableCell>
                      <TableCell id="serviceHours">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: processContent(openingHours),
                          }}
                        />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Towing fee:</TableCell>
                      <TableCell id="serviceTowingFee">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: processContent(towingFee),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Storage fee:</TableCell>
                      <TableCell id="serviceStorageFee">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: processContent(storageFee),
                          }}
                        />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Additional information:</TableCell>
                      <TableCell id="serviceInfo">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: processContent(additionalInformation),
                        }}
                      />
                      </TableCell>                    
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </section>
        </Box>
            <Box sx={{ mt: 0 }} style={{marginBottom: '20px', marginTop: '20px'}}>
              {apiSearch && (
                <div>
                  <Typography><span style={{ fontWeight: 300, fontSize: '0.875rem', color: '#555'}}>you will be redirected to an external site</span></Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.href = apiSearch}
                  >
                    Go to search vehicle page
                  </Button>
                </div>

              )}
            </Box>
      </Container>

      <Box sx={{ backgroundColor: '#38ADEF', padding: '20px', width: '100%', textAlign: 'center', marginTop: 'auto' }}>
        <Typography sx={{ fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
          Contact us for support or refund of the towing fee:&nbsp;&nbsp;
          <Link href="mailto:claims@safecityparking.com" sx={{ color: 'white' }}>claims@safecityparking.com</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default SearchEngine;