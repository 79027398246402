import React, { useState } from 'react';
import logo from './assets/logo-final.png';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import './Login.css'; // New CSS file for cleaner styles

const Login = () => {
    // eslint-disable-next-line
    const [licensePlate, setLicensePlate] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [country, setCountry] = useState(null);
    const [termsChecked, setTermsChecked] = useState(false);
    const [errors, setErrors] = useState({ licensePlate: false, mobileNumber: false, country: false });
    const navigate = useNavigate();

    const options = countryList().getData().map(country => ({
        ...country,
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`https://flagcdn.com/w20/${country.value.toLowerCase()}.png`}
              alt={country.label}
              style={{ marginRight: 10 }}
            />
            {country.label}
          </div>
        ),
    }));

    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption);
    };
  
      // Allow only numbers and limit length to 9 or 10 for mobile number
      const handleMobileNumberChange = (e) => {
          const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
          if (value.length <= 10) {
              setMobileNumber(value);
          }
      };

    const checkData = () => {
      const newErrors = {
        mobileNumber: (mobileNumber.length < 9 || mobileNumber.length > 10) || mobileNumber !== '0542224411',
        country: !country,
      };

      setErrors(newErrors);

      return !Object.values(newErrors).includes(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (termsChecked && checkData()) {
            localStorage.setItem('isAuthenticated', 'true'); 
            navigate('/search-engine');
        }
    };

    const isFormValid = termsChecked && (licensePlate || (country && mobileNumber));

    const filterOption = (candidate, input) => {
        const searchValue = input.toLowerCase();
    
        // If label contains JSX, we extract text directly
        const label = typeof candidate.label === 'string' 
            ? candidate.label.toLowerCase()
            : candidate.label.props.children[1]?.toLowerCase() || ''; // Extract text from JSX
    
        const value = candidate.value.toLowerCase();
    
        // Match only if label or value starts with the search input
        return label.startsWith(searchValue) || value.startsWith(searchValue);
    };
    

    return (
      <div className="container">
          {/* Header */}
          <div className="header">
              <img src={logo} alt="Safe City Parking" width="300" />
          </div>
  
          {/* Main Content */}
          <div className="main-content">
              <form className="login-form" onSubmit={handleSubmit}>
                  <div className="form-header">To search your vehicle, enter the following details</div>
  
                  {/* License Plate Input */}
                  <div className="form-group">
                      <input
                            type="text"
                            className="input-field"
                            placeholder="Enter license plate"
                        />
                  </div>
  
                  {/* Country Code and Mobile Number */}
                  <div className="form-inline-group">
                      <div className="form-inline-item">
                      <Select
                        options={options}
                        value={country}
                        onChange={handleCountryChange}
                        placeholder="Country Code"
                        isSearchable={true}
                        filterOption={filterOption}
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                padding: '0px', // Reset padding
                                borderColor: state.isFocused || !errors.country ? '#ddd' : 'red',
                                boxShadow: 'none',
                                '&:hover': { borderColor: '#38ADEF' },
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                color: '#888', // Match placeholder color
                                textAlign: 'left',
                                fontSize: '1rem',
                            }),
                            input: (provided) => ({
                                ...provided,
                                margin: '0px', // Align input text with placeholder
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                color: '#333', // Text color after selection
                            }),
                        }}
                    />
                        {errors.country && <span className="error-text">Country is required</span>}
                      </div>
                      <div className="form-inline-item">
                          <input
                              type="text"
                              value={mobileNumber}
                              onChange={handleMobileNumberChange}
                              className={`input-field ${errors.mobileNumber ? 'invalid' : ''}`}
                              placeholder="Mobile Number"
                              maxLength={10}
                          />
                          {errors.mobileNumber && <span className="error-text">Must be 9-10 digits or in system</span>}
                      </div>
                  </div>
  
                  {/* Terms and Conditions */}
                  <div className="terms-group" style={{marginTop: '20px'}}>
                      <input
                          type="checkbox"
                          checked={termsChecked}
                          onChange={(e) => setTermsChecked(e.target.checked)}
                      />
                      <label>I agree to the <a href="/terms">terms and conditions</a></label>
                  </div>
  
                  {/* Submit Button */}
                  <button
                      type="submit"
                      className="submit-button"
                      disabled={!isFormValid}
                  >
                      Sign up
                  </button>
              </form>
          </div>
  
          {/* Footer */}
          <div className="footer">
              Contact us for support or refund of the towing fee:&nbsp;
              <a href="mailto:claims@safecityparking.com">claims@safecityparking.com</a>
          </div>
      </div>
  );
  
};

export default Login;
